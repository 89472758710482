.experiment-card {
  width: 450px;
  background-color: white;
  border-radius: 4px;
  /* Add shadows to create the "card" effect */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
}

/* On mouse-over, add a deeper shadow */
.experiment-card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.experiment-card > * {
  padding: 10px;
}

.experiment-card-title {
  border-bottom: 1px solid rgba(0, 0, 0, 0.6);
}

/* Add some padding inside the card container */
.experiment-card-image {
  height: 100px;
  width: 100px;
}

.experiment-card-description {
  margin: 0 12px;
}

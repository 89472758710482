body {
  background-color: #bcd2ee;
  width: 100%;
}

.app {
  min-height: 100vh;
}

.experiment-title {
  margin-top: 40px;
  background-color: white;
  height: 200px;
  width: 100%;
  text-align: center;
  font-size: 5em;
  line-height: 200px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.experiment-content {
  margin-top: 16px;
}

.column-title {
  padding: 6px;
  font-size: 1.2em;
}

.column-content {
  margin-top: 6px;
  padding: 6px;
}

.tiles {
  width: 200px;
  height: 200px;
}

.tile-input {
  margin-left: 4px;
  width: 190px;
  margin: 3px;
}

.game-cell {
  background-color: white;
  font-size: 2em;
  border-radius: 4px;
  border: 1px solid black;
  margin: 3px;
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 40px;
}

.found-words {
  width: 50%;
}

.found-word {
  margin: 3px;
}

.found-word::after {
  content: ",";
}

.things-left {
  color: white;
  background-color: rgb(50, 50, 50);
  opacity: 0.8;
  padding: 30px;
  text-align: left;
  margin-top: 200px;
  padding-left: 90px;
  width: 425px;
}

.things-left-list {
  font-size: 0.9em;
  margin-top: 6px;
}

nav {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100%;
  height: 40px;
}

nav .title {
  float: left;
  color: white;
  padding: 0.2em 2em;
  font-size: 1.5em;
  font-weight: lighter;
  letter-spacing: 1px;
  transition: 0.25s ease-in-out;
}

nav ul {
  list-style: none;
  margin: 0;
  padding: 0.2em 2em;
  float: right;
}

nav ul li {
  display: inline-block;
  margin: 0;
  padding: 0.2em 0.7em;
}

nav a {
  width: 100%;
  height: 100%;
  color: white;
  text-decoration: none;
  font-weight: lighter;
  letter-spacing: 1px;
  transition: 0.25s ease-in-out;
}

nav a:hover {
  color: rgb(220, 120, 0);
}

nav .nav-bg {
  content: "";
  position: absolute;
  display: block;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background: rgb(50, 50, 50);
  transition: 0.45s ease-in-out;
}

.nav-li > :not(:first-child) {
  margin-left: 12px;
}

.static-bg {
  content: "";
  position: absolute;
  display: block;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background: rgba(50, 50, 50, 1);
  transition: 0.45s ease-in-out;
}

.bg-hidden {
  top: -100%;
  opacity: 0;
}

.bg-visible {
  top: 0;
  opacity: 1;
}

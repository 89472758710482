.app-splash {
  min-height: calc(100vh - 33px);
}

.image-credit {
  margin-top: -20px;
  color: white;
  font-size: 0.8em;
}

.image-credit a {
  color: white;
}

.image-credit a:hover {
  color: rgb(220, 120, 0);
}

.app-welcome {
  background-image: url("/public/images/spacex--p-KCm6xB9I-unsplash.jpg");
  background-position: bottom;
  background-size: 100% auto;
  background-repeat: no-repeat;
  height: calc(100vh * 0.8);
  padding-top: 1px;
}

.app-welcome-content {
  color: white;
  background-color: rgb(50, 50, 50);
  opacity: 0.8;
  padding: 30px;
  z-index: 1000;
  text-align: left;
  margin-top: 300px;
  padding-left: 90px;
  width: 425px;
}

.app-welcome-content > :not(:first-child) {
  margin-top: 0.5em;
}

.app-body {
  align-items: center;
  width: 100%;
}

.app-experiments-header {
  background-color: white;
  text-align: center;
  line-height: 50px;
  height: 50px;
  width: 100%;
  margin-top: 4px;
  font-size: 1.5em;
}

.app-experiments {
  max-width: 1040px;
}

.app-experiment {
  margin: 8px;
}
